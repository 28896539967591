import React from "react";
import LayoutExamples from "../../../layouts/LayoutExamples";
import Seo from "../../../components/seo";
import { Row, Col, Divider } from "antd";
import ButtonsNavigation from "../../../components/ui/ButtonsNavigation.js";
import ChartTestclientPharma from "../../../client/testclient-pharma/interactions/chart/Chart.js";
import ChartShippingProces from "../../../client/testclient-pharma/json/shipping-process.json";

const ExamplePremiumShipping = () => (
  <LayoutExamples>
    <Seo
      title="Premium example | Shipping process"
      description="inFlow helps professional organisations create interactive decision-making tools such as flow charts, decision trees or algorithms. Improve your process and increase engagement with our world-class tools."
    />
    <Row
      justify="center"
      align="middle"
      style={{
        backgroundColor: "#f1f3f5",
        minHeight: "80vh",
      }}
    >
      <Col xs={22} sm={20} md={20} lg={16} xl={12}>
        <ChartTestclientPharma
          entry={ChartShippingProces}
          firstNodeId="node-shipping1"
        />
      </Col>
    </Row>
    <Divider dashed style={{ margin: "0" }} />
    <Row
      justify="center"
      align="middle"
      style={{ backgroundColor: "#f8f9fa", padding: "48px 0" }}
    >
      <Col>
        <ButtonsNavigation
          linkPrevious="Previous"
          linkPreviousTo="/examples/premium/example-reservation"
          linkNext="Next example"
          linkNextTo="/examples/medical/example-tachycardia"
        />
      </Col>
    </Row>
  </LayoutExamples>
);

export default ExamplePremiumShipping;
